.index {
  background-color: #05321e;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.index .container {
  position: relative;
  width: 100%;
  padding: 20px;
}

.index .LOGO-MCCHST {
  height: 180px;
  position: relative;
  margin: 20px;
  margin-bottom: 50px;
  width: 180px;
  cursor: pointer;
}

.index .text-container {
  padding: 5px;
  color: antiquewhite;
}

.form-caption {
  color: antiquewhite;
  font-weight: 900;
  padding: 20px;
}

.button-container {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
